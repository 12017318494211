.inner-header {
    background-color: white;
    padding: 16px 24px;
    border-radius: 6px;
}

.header-rappels {
    font-weight: bold;
    color: red;
}

.layout {
    background: #8360c3; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #8360c3, #2ebf91); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #8360c3, #2ebf91); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background-size: "cover";
}
  
.container {
    max-width: 408px;
    margin: auto;
}

.title {
    text-align: center;
    color: #626262;
    font-size: 30px;
    letter-spacing: -0.04em;
}

.imageContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
}

.chat-ctn,
.chat-msgsctn,
.chat-msgctn-inner {
    display: flex;
    flex-direction: column;
}

.chat-msgsctn {
    height: 265px;
    overflow-y: auto;
    flex-direction: column-reverse;
}

.chat-actionsctn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 5px;
}

.chatmsg-ctn {
    width: 47%;
    background-color: #f0f2f5;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 2px 6px;
    margin-bottom: 8px;
}

.chatmsg-ctn.full-width {
    width: 100%;
}

.chatmsg-left {
    align-self: flex-start;
}

.chatmsg-right {
    align-self: flex-end;
    background-color: #67be23;
    color: white;
}

.chatmsg-origin {
    font-size: 0.8rem;
    font-weight: 600;
}

.chatmsg-message {
    font-size: 0.9rem;
}

.chatmsg-date {
    font-size: 0.7rem;
    align-self: flex-end;
}

/*TMP*/
.ant-layout-sider {
    background-color: #143642;
}

.ant-layout-sider-zero-width-trigger {
    background-color: #143642;
}
/*END TMP*/

.custom-header {
    background-color: white; 
    margin-bottom: 20px;
}
.custom-header > div.ant-page-header-content {
    padding-top: 0;
}